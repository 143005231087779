<!--
 * @Author       : a大师兄
 * @Date         : 2021-05-24 10:34:04
 * @LastEditors  : a大师兄
 * @LastEditTime : 2023-02-22 15:11:55
 * @Description  : 404页面处理
-->
<template>
	<van-empty image="network" description="404" />
</template>

<script>

export default {
    name: 'ErrPage'
};
</script>

<style scoped lang="less">
    .center{
        font-size: 25px;
    }
</style>
